import { default as _91_91slug_93_93ifvjEa22njMeta } from "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/[[slug]].vue?macro=true";
import { default as _404NUHc3krGEqMeta } from "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/404.vue?macro=true";
import { default as _91slug_93Yz4VVycZFtMeta } from "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/work/[slug].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug?",
    meta: _91_91slug_93_93ifvjEa22njMeta || {},
    component: () => import("/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/[[slug]].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/404.vue")
  },
  {
    name: "work-slug",
    path: "/work/:slug()",
    component: () => import("/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/work/[slug].vue")
  }
]