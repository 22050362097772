export let useProgressStore = defineStore('progress', () => {
  let trackedProgresses = ref(new Set())
  let isComplete = computed(() =>
    [...trackedProgresses.value].every(progress => progress.isFinished),
  )

  return {
    trackedProgresses,
    isComplete,
    clear() {
      trackedProgresses.value.clear()
    },
    track(progress) {
      if (import.meta.env.SSR) return

      window.p ??= []
      p.push(progress)
      trackedProgresses.value.add(progress)

      progress.onDispose(() => {
        trackedProgresses.value.delete(progress)
      })
    },
  }
})
