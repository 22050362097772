<script setup></script>

<template>
  <div class="animated-cross">
    <svg
      aria-hidden="true"
      focusable="false"
      width="23"
      height="24"
      viewBox="0 0 23 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#FFCE32" fill-rule="evenodd">
        <path d="M8.277 7.441h6.919V.522H8.277v6.919ZM9.85 5.867h3.771v-3.77H9.85v3.77Z" />
        <path
          d="M22.644 8.818h-8.919v5.344H9.877V8.818H.96v6.92h7.318v7.343h6.92v-7.344h7.447V8.818ZM15.3 14.162h5.771v-3.77h-5.77v3.77Zm-5.45 1.575H13.621v5.77h-3.77v-5.77Zm-7.317-5.345h5.77v3.77h-5.77v-3.77Z"
        />
      </g>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.animated-cross {
  --width: max(12vmin, 64px);
  --border-width: max(0.75vmin, 2px);
  --border-offset: max(0.5vmin, 2px);

  position: relative;
  width: var(--width);
  height: var(--width);
  background-color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;

  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: calc(-1 * (var(--border-width) + var(--border-offset)));
    top: calc(-1 * (var(--border-width) + var(--border-offset)));
    transform: translate(-50%, -50%);
    width: calc(100% + 2 * (var(--border-width) + var(--border-offset)));
    height: calc(100% + 2 * (var(--border-width) + var(--border-offset)));
    border-radius: 50%;
    border: var(--border-width) solid transparent;
    border-bottom-color: #000;
    animation: rotation 1.5s infinite;
    animation-timing-function: cubic-bezier(0.2, 0.55, 0.8, 0.45);
  }

  svg {
    width: 50%;
    height: auto;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shine {
  from {
    mask-position: 150%;
    mask-size: 300%;
  }

  to {
    mask-position: -50%;
    mask-size: 200%;
  }
}
</style>
