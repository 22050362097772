import { v4 as uuidv4 } from 'uuid'

export function uuid() {
  return uuidv4()
  // // Built-in UUID generator
  // if (typeof globalThis.crypto?.randomUUID === 'function') return crypto.randomUUID()

  // // Fallback implementation
  // return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
  //   (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  // )
}

/**
 * @param {string} text
 */
export function revHash(text) {
  if (text.length === 0) return hash

  let hash = 0

  for (let i = 0; i < text.length; i++) {
    let chr = text.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }

  return hash.toString(16)
}

export function encodeBase64(text) {
  if (typeof globalThis.btoa === 'function') return globalThis.btoa(text)
  return Buffer.from(text).toString('base64')
}

export function ensureArray(value) {
  return Array.isArray(value) ? value : [value]
}

export const get8bitHex = (color6BitHex, opacityFraction) =>
  `${color6BitHex}${Math.floor(opacityFraction * 255)
    .toString(16)
    .padStart(2, '0')}`

export const modulo = (a, b) => ((+a % (b = +b)) + b) % b

/**
 * Invoke an array of functions without any parameters, ignoring the return value.
 * This is useful for calling multiple unlistener functions.
 *
 * @param {Array<() => void>} fns
 * @returns {() => void}
 */
export function invokeFunctions(fns) {
  for (let fn of fns) {
    fn()
  }
}

/**
 * Create a function that calls the given functions
 * without any parameters, ignoring the return value.
 * This is useful for grouping multiple unlistener functions.
 *
 * @param {Array<() => void>} fns
 * @returns {() => void}
 */
export function chainFunctions(fns) {
  return () => invokeFunctions(fns)
}

/**
 * Create a promise that resolves after the given time
 *
 * @param {number} ms
 * @returns {Promise<void>}
 */
export function delay(ms) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), ms)
  })
}

/**
 * Check whether a file path/URL is a supported video format
 *
 * @param {string} file
 */
export function isVideo(file) {
  return /\.(mp4|mov|webm|ogv)$/i.test(file)
}

/**
 * Removes Vue reactivity from a value - like toRaw, but recursive
 *
 * @template {T}
 * @param {T} value
 * @returns {T}
 */
export function toRawDeep(value) {
  let rawValue = toRaw(value)
  if (typeof rawValue !== 'object' || rawValue === null) return rawValue

  if (Array.isArray(rawValue)) {
    return rawValue.map(toRawDeep)
  } else {
    return Object.fromEntries(
      Object.entries(rawValue).map(([key, value]) => [key, toRawDeep(value)]),
    )
  }
}

/**
 * Convert a fraction to a percentage string
 *
 * @param {number} fraction
 */
export function toPercent(fraction) {
  return `${Math.round(fraction * 100)}%`
}

/**
 * Like nextTick(), but with the number of ticks passed
 */
export function futureTick(count, callback) {
  if (!Number.isInteger(count) || count < 0) {
    throw new TypeError('Count needs to be a non-negative integer')
  }

  if (count === 0) {
    if (typeof callback === 'function') callback()
    return Promise.resolve()
  }

  return nextTick().then(() => futureTick(count - 1, callback))
}

/**
 * Check the browser's WebP support
 */
export function checkWebpSupport() {
  return new Promise(resolve => {
    let img = new Image()
    img.onload = function () {
      let result = img.width > 0 && img.height > 0
      resolve(result)
    }
    img.onerror = function () {
      resolve(false)
    }
    img.src = 'data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA'
  })
}

/**
 * Scroll to the hash of the page
 */
export function scrollToHash(options = {}) {
  if (import.meta.env.SSR) return
  if (!location.hash) return

  let target = document.querySelector(location.hash)

  if (!target) return

  target.scrollIntoView({
    block: 'start',
    ...options,
  })
}
