<script setup>
let { data: contact, pending } = await useWordpressData('wp-remote/contact')
const route = useRoute()

const isPrivacyPage = computed(() => route.fullPath === contact.value?.privacyPagePath)

const hideDuration = 1000 * 60 * 60 * 24 * 365 // 365 days
const now = Date.now()
const hideTime = useLocalStorage('hide-privacy', 0)
const hidden = computed(() => now - hideTime.value < hideDuration)
</script>

<template>
  <transition name="slide">
    <div v-if="contact && !hidden && !isPrivacyPage" class="privacy-banner">
      <div class="privacy-banner-content">
        <p class="privacy-banner-prose">
          Wir verwenden Cookies und externe Dienste auf dieser Website.
          <ActionTrigger class="privacy-banner-link" :link="contact.privacyPagePath"
            >Datenschutzerklärung ansehen</ActionTrigger
          >
        </p>
        <Button class="privacy-button" @click="hideTime = Date.now()">
          Alles klar
          <template #iconAfter>
            <!-- prettier-ignore -->
            <svg class="privacy-arrow" aria-hidden="true" focusable="false" width="52" height="52" viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg"><path d="M26 0c14.36 0 26 11.64 26 26S40.36 52 26 52 0 40.36 0 26 11.64 0 26 0Zm-.687 17.5h-3.289l5.807 8.7L22 34.5h3.282l6.01-8.283-5.98-8.717Z" fill="currentColor"/></svg>
          </template>
        </Button>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.privacy-banner {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  border-radius: 2rem;
  background-color: #fff;
  color: #000;
  font-size: 1rem;
  padding: 1.5rem 1.5rem 1.2rem;
  z-index: 100;

  p {
    margin: 0;
  }

  @include mediaMD {
    padding-top: 1rem;
    padding-bottom: 0.8rem;
    padding-right: 1.25rem;
  }
}

.privacy-banner-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 1rem;
  align-items: center;
}

.privacy-banner-prose {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 0.25rem;
}

.privacy-banner-link {
  text-decoration: underline;
  text-underline-offset: 0.2em;
  text-decoration-color: var(--primary-color);
}

.privacy-button {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    right: calc(-0.2em + 0.25rem);
    top: calc(-0.1rem + 0.25rem);
    height: 2rem;
    width: 2rem;
    background-color: var(--primary-color);
    z-index: -1;
    border-radius: 100%;
  }
}

.privacy-arrow {
  height: 2.5rem;
  width: 2.5rem;
  margin-top: -0.1rem;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 400ms ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(100%) translateY(1rem);
}
</style>
